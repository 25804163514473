/* ---------------------------------------------------------------------------- */
/* ------------------------------------Icon font------------------------------- */
/* ---------------------------------------------------------------------------- */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/MaterialIcons-Regular.eot'); /* For IE6-8 */
    src: local('Material Icons'), local('MaterialIcons-Regular'), url(../fonts/MaterialIcons-Regular.woff2) format('woff2'), url(../fonts/MaterialIcons-Regular.woff) format('woff'), url('../fonts/MaterialIcons-Regular.ttf') format('truetype');
}

.icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}

.icons-16 {
    font-size: 16px;
}

.icons-24 {
    font-size: 24px;
}

.icons-32 {
    font-size: 32px;
}
/* ---------------------------------------------------------------------------- */
/* ------------------------------------Common---------------------------------- */
/* ---------------------------------------------------------------------------- */
html, body {
    height: 100%;
}

body {
    margin: 0;
    line-height: 1.5;
    font-size: 10pt;
    color: #373a3c;
    font-family:Arial
    /*background-color: #fbfbfb;*/
}

a, img {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

input {
    outline: none;
}
/* ---------------------------------------------------------------------------- */
/* ------------------------------------Layout---------------------------------- */
/* ---------------------------------------------------------------------------- */
#header, #content {
    margin: 0;
    width: 100%;
    float: left;
    padding: 0;
}

#header {
    line-height: 10px;
    height: 45px;
    padding: 6px 0 2px 0;
    float: left;
    box-shadow: 0 0 3px #d3d3d3;
    -webkit-box-shadow: 0 0 3px #d3d3d3;
    -moz-box-shadow: 0 0 3px #d3d3d3;
    background: #f2f2f2;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    border-bottom: #d3d3d3 solid 1px;
}

.body {
    width: 85%;
    max-width:1100px;
    margin: auto;
}

@media screen and (max-width:768px) {
    .body {
        width: 100%;
    }
}

@media screen and (max-width:480px) {

    .col-33, .col-50 {
        /*width: 100%;*/
    }
}
/* ---------------------------------------------------------------------------- */
/* ------------------------------------Header---------------------------------- */
/* ---------------------------------------------------------------------------- */
#header i {
    color: #6d6d6d;
}

    #header i:hover {
        color: #000;
    }

#left, #right {
    line-height: 30px;
    margin-top: -2px;
    position: absolute;
}

#left {
    left: 0px;
    padding-left: 20px;
}

#right {
    right: 0px;
    padding-right: 30px;
    float: right;
    text-align: right;
    margin-top: 1px;
}

    #right .img-circle {
        cursor: pointer;
    }

    #right .user {
        float: left;
    }

        #right .user ul {
            margin-top: 10px;
        }

    #right .notify {
        float: left;
        margin: 5px 2px 0 0;
    }

        #right .notify i {
            color: #c2c2c2;
        }

            #right .notify i:hover {
                color: #000;
                cursor: pointer;
            }

    #right .number {
        font-size: 0.8em;
        background: red;
        border-radius: 100px;
        margin: 0;
        padding: 1px;
        color: white;
        margin-left: -5px;
        margin-top: 2px;
        float: right;
        height: 14px;
        width: 14px;
        line-height: normal;
        position: absolute;
        z-index: 10;
    }

    #right .name {
        float: left;
        margin-top: 2px;
    }

    #right .user-head {
        text-align: center;
        font-weight: bold;
    }

    #right .user-footer {
        text-align: right;
    }

.notify .col-md-12 {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

.notice-block {
    float: left;
    width: 220px;
    padding: 0 10px;
    line-height: 20px;
    font-size: 0.9em;
}

    .notice-block label {
        float: right;
        font-size: 0.8em;
    }

#right .dropmenu {
    float: right;
}

#left .dropmenu {
    float: left;
}

#right .dropmenu .menu-icon {
    margin-right: 0px;
    margin-left: 10px;
}

#right .dropmenu #menu {
    right: 0;
    left: auto;
    text-align: left;
    border-left: 3px #c2c2c2 solid;
}


.menu-icon {
    float: left;
    cursor: pointer;
}

.logo {
    margin-left: 10px;
    float: right;
}

    .logo a {
        text-decoration: none;
    }

    .logo .logo-img {
        max-width: 60px;
    }

    .logo .logo-text {
        max-height: 25px;
        margin-top: -2px;
    }

.menu-icon {
    margin-top: 5px;
}

.search {
    margin: auto;
    width: 270px;
    padding-left: 40px;
}

    .search .box {
        float: left;
        padding: 2px 2px 2px 8px;
        background: #fbfbfb;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border: 1px solid #eee;
    }

    .search i {
        float: right;
        color: #ee1b2e;
        margin-top: 2px;
        margin-right: 3px;
    }

    .search input {
        float: right;
        border: none;
        background: none;
        padding: 5px;
        line-height: 17px;
    }

    .search .box:hover {
        background: #fff;
    }

@media screen and (max-width:768px) {
    /*#left {
        padding-left: 0px;
    }

    #right {
        padding-right: 0px;
    }*/

    #right .name {
        display: none;
    }

    .logo {
        /*margin-left: 0px;*/
    }

        .logo .logo-text {
            display: none;
        }
}

@media screen and (max-width:480px) {
    #left {
        padding-left: 15px;
    }

    #right {
        padding-right: 15px;
    }

    .search {
        width: 180px;
        padding-left: 50px;
    }

        .search input {
            width: 80px;
        }
}
/* ---------------------------------------------------------------------------- */
/* ------------------------------------Menu------------------------------------ */
/* ---------------------------------------------------------------------------- */
#dropmenu {
    float: left;
}

#menu {
    width: 320px;
    padding: 20px;
    float: left;
    background-color: #fff;
    display: none;
    position: fixed;
    top: 45px;
    left: 0;
    bottom: 80px;
    z-index: 10;
    overflow: scroll;
    overflow-x: hidden;
    line-height: 30px;
    /*font-size:0.9em;*/
}

    #menu ul {
        margin-top: 10px;
        border-top: 1px solid #e8e8e8;
        float: left;
        width: 100%;
    }

        #menu ul ul {
            padding-left: 20px;
            border: none;
            margin-top: 0;
        }

    #menu li, #menu li a {
        color: #373a3c;
    }

        #menu li.mn-head {
            color: #ee1b2e;
            font-weight: bold;
        }

        #menu li a:hover {
            color: #000;
        }

        #menu li a.active {
            color: #ee1b2e;
        }

        #menu li i.icon-background {
            color: white;
            background: #ee1b2e;
            padding: 2px;
            margin: 4px 5px 0 0;
            float: left;
            border-radius:5px;
            -webkit-border-radius:5px;
            -moz-border-radius:5px;
        }

    #menu ul ul li i.icon-background {
        background: gray;
    }

    #menu li i.icon-right {
        float: right;
    }

    #menu .menu-top {
        width: 100%;
        float: left;
        line-height: 30px;
    }

    #menu .hot-button {
        background: #ee1b2e;
        float: left;
        color: white;
        text-indent: 5px;
        width: 100%;
    }

        #menu .hot-button i.icon-background {
            color: white;
            margin-top: 2px;
            float: left;
        }

.sys-link {
}

    .sys-link a {
        height: 30px;
        font-weight: bold;
        color: black;
    }

#menu .sys-link a i {
    color: #ee1b2e;
    float: left;
    margin-top: -2px;
}
/* ---------------------------------------------------------------------------- */
/* ----------------------------------Content----------------------------------- */
/* ---------------------------------------------------------------------------- */
#content {
    margin-top: 50px;
    float: left;
    margin-bottom: 20px;
}

.card {
    float: left;
    width: 100%;
}

div.open-full {
    float: right;
    color: #6d6d6d;
    cursor: pointer;
    padding: 5px;
    margin-bottom: -40px;
    position: relative;
    z-index: 10;
}

    div.open-full i {
    }

.card .open:hover {
    color: #ee1b2e;
}


.card-header {
    line-height: 30px;
    padding: 5px 15px 0px 15px;
    font-size: 1em;
    font-weight: bold;
}

    .card-header .tool {
        /*margin-bottom:2px;*/
    }

        .card-header .tool select {
            width: 70px;
            font-weight: normal;
            padding: 4px;
            border: 1px solid #d1d1d1;
            border-radius: 3px;
            outline: none;
            cursor: pointer;
        }

.card-content {
    background: #fff;
    box-shadow: 0 0 3px #d1d1d1;
    -webkit-box-shadow: 0 0 3px #d1d1d1;
    -moz-box-shadow: 0 0 3px #d1d1d1;
    position: relative;
    float: left;
    width: 100%;
    border: 1px solid #ddd;
}

.card:hover .card-content {
    background: #fff;
}

.card-content .row {
    width: 100%;
    float: left;
    padding: 10px 20px;
}

.full {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    overflow: auto;
    overflow-x: hidden;
}

.hot-link {
    padding-top: 10px;
}

    .hot-link a {
        width: 100%;
        text-align: center;
        float: left;
        color: gray;
    }

        .hot-link a:hover {
            color: #ee1b2e;
        }

/* ---------------------------------------------------------------------------- */
/* ------------------------------------Footer---------------------------------- */
/* ---------------------------------------------------------------------------- */
#footer {
    /*border-top: 1px solid #e5e5e5;*/
    /*background: #f7f7f7;*/
}

.system-info {
    width: 100%;
    line-height: 30px;
    float: left;
    background: #f2f2f2;
    border-top: #d3d3d3 solid 1px;
    padding: 5px 0;
    font-size: 0.9em;
}

    .system-info .col-md-4 {
        min-width: 320px;
    }

        .system-info .col-md-4 select {
            padding: 4px;
            width: 140px;
            border-radius: 3px;
            border: 1px solid #d3d3d3;
        }

        .system-info .col-md-4 input[type=button] {
            margin-left: 2px;
            border: none;
            padding: 4px 5px;
            background: #ee1b2e;
            color: white;
        }

#footer .identify {
    float: left;
    width: 100%;
    padding: 3px 0;
    background: #ee2839;
    text-align: center;
    color: white;
    font-size: 0.9em;
    z-index: 2;
}

    #footer .identify a {
        color: white;
    }


/* direct link */
.direct-link {
    line-height: 25px;
    background: #f2f2f2;
    border-top: #d3d3d3 solid 1px;
    padding: 5px 0;
}

    .direct-link .col-md-3 {
        min-height: 130px;
    }

    .direct-link a {
        color: #6d6d6d;
    }

        .direct-link a:hover {
            color: black;
        }

@media screen and (max-width:768px) {
    .system-info .col-md-4 {
        text-align: center;
    }

    .direct-link .col-md-3 {
        text-align: center;
    }
}

@media screen and (max-width:480px) {
}

/* ---------------------------------------------------------------------------- */
/* ------------------------------------Customize------------------------------- */
/* ---------------------------------------------------------------------------- */
[class*="col-md"] {
    float: left;
}

.col-md-12 {
    width: 100%;
}

.col-md-9 {
    width: 80%;
}

.col-md-8 {
    width: 66.6%;
}

.col-md-6 {
    width: 50%;
}

.col-md-4 {
    width: 33.3%;
}

.col-md-3 {
    width: 25%;
}

.col-md-2 {
    width: 16.6%;
}

@media screen and (max-width:768px) {
    .col-md-9, .col-md-8, .col-md-6 {
        width: 100%;
    }

    .col-md-4 {
        width: 100%;
    }

    .col-md-3 {
        width: 50%;
    }


    .col-md-2 {
        width: 30%;
    }
}

@media screen and (max-width:480px) {
    .col-md-4, .col-md-3 {
        width: 100%;
    }

    .col-md-2 {
        width: 50%;
    }
}

.top-26 {
    margin-top: 26px;
}
.top-24 {
    margin-top: 24px;
}
.top-20 {
    margin-top: 20px;
}

.top-30 {
    margin-top: 30px;
}

.top-40 {
    margin-top: 40px;
}
.top-15 {
    margin-top: 15px;
}
.top-10 {
    margin-top: 10px;
}

.top-6 {
    margin-top: 6px;
}
.top-3 {
    margin-top: 3px;
}
.left {
    float: left;
}

.center {
    text-align: center;
}

.middle {
    vertical-align: middle;
}

.right {
    float: right;
}

.error {
    color: red;
}

.pending {
    color: yellow;
}

.title {
    font-size: 13pt;
}

.title-income {
    margin-top:5px;
    width:100%;
    float:left;
    text-align:center;
    text-transform: uppercase;
}
.title-income label{
    font-size: 15px;
    font-weight: bold;
    color: white;
    background: #ee1b2e;
    padding: 0 10px;
    border-radius: 10px;
    margin-bottom: 0;
    }
.title-body-popup{
	font-size: 17px;
}
.margin-left-1 {
    margin-left: 1px;
}
.required {
    color:red;
    /*font-weight:bold;*/
    padding:2px;
}
/* ---------------------------------------------------------------------------- */
/* ------------------------------------Portal---------------------------------- */
/* ---------------------------------------------------------------------------- */
#slide-container {
    margin: 0 auto;
    width: 100%;
    float: left;
}

#slider-m {
    display: none;
}

@media screen and (max-width:768px) {
    #slider {
        display: none;
    }

    #slider-m {
        display: block;
    }
}

/*News*/
.news-block {
    width: 100%;
    float: left;
    margin-top: 10px;
}

    .news-block .news-image, .news-block .news-content {
        float: left;
    }

    .news-block .news-image {
        width: 30%;
        margin-right: 10px;
    }

    .news-block .news-content {
        max-width: 60%;
    }

    .news-block .news-image img {
        float: left;
        width: 100%;
    }

.news-content b, .news-content p {
    float: left;
    text-align: justify;
}

.news-block a {
    color: #6d6d6d;
}

.col-2 {
    line-height: 30px;
}

    .col-2 .news-block {
        margin-top: 20px;
    }
/* ---------------------------------------------------------------------------- */
/* ------------------------------------Form------------------------------------ */
/* ---------------------------------------------------------------------------- */
form .title3 {
    padding: 0;
    float: left;
    margin-bottom: -20px;
    margin-top:-5px;
}

    form .title3 label {
        border-bottom: 1px solid #ddd;
        float: left;
        width: 100%;
        color: #ee1b2e;
        font-weight: bold;
        font-size: 1.1em;
    }

form .line {
    float: left;
    width: 100%;
    margin-top: 5px;
}
.line {
    float: left;
    width: 100%;
    /*margin-top: 5px;*/
}
.form-control {
    border-radius:0 !important;
    /* border:  1px solid #ccc !important; */
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    height:22px !important; 
    line-height:20px;
    padding: 0 5px;
}
.form-control:focus {
        border-color: #66afe9;
        -webkit-box-shadow: 0 1px 1px #fff,0 0 1px #66afe9;
        box-shadow: 0 1px 1px #fff,0 0 1px #66afe9;
    }
label{
    font-weight: normal;
}
.btn-xs{
    padding: 1px 3px;
}

.resize-width:focus{
    position: fixed;
    z-index: 999;
    top: 50%; left: 30%;
    width: 40%;
    box-shadow: 0 0 3px;
}
/* ---------------------------------------------------------------------------- */
/* -----------------------------------Scroll to top---------------------------- */
/* ---------------------------------------------------------------------------- */
.scrollToTop {
    text-align: center;
    background: #e8e8e8;
    font-weight: bold;
    text-decoration: none;
    position: fixed;
    bottom: 20px;
    right: 5px;
    display: none;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    width: 35px;
    height: 35px;
}

    .scrollToTop:hover {
        text-decoration: none;
    }



.border-bottom{
    border-bottom: 1px solid #eee;
}

.title4{
    color: #ee1b2e;
    font-weight: bold;
    font-size: 1.1em;
}
.alert2 {
    padding: 2px 5px;
    border: 1px solid transparent;
    border-radius: 2px;
}
.full-width{
    width: 100%
}
/* ---------------------------------------------------------------------------- */
/* -----------------------------------Customize-------------------------------- */
/* ---------------------------------------------------------------------------- */

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
padding-left: 10px;
padding-right: 10px
}

.padding-0 {
    padding: 0px;
    padding-right: 1px;
}
.padding-left-0{
    padding-left: 0;
} 
.padding-right-0{
    padding-right: 0;
} 
.none-padding{
    float: left;
    padding:0;
    margin: 0;
}
.nav > li > a{
    padding: 5px 10px;
}
.text-right{
    text-align: right;
}
hr{
    padding: 5px;
    margin: 0
}
.table-colospan{
    margin: 0!important;
}
.table-colospan th, .table-colospan td{
    padding: 2px!important;
}
.card-content .row{
    padding-top: 5px!important;
    padding-bottom: 5px!important;
}
.input-group-btn .btn{
    padding: 0px 10px !important;
    height: 22px;
    line-height: 20px;
}
.table{
    margin-bottom: 0;
}
.highlightedText {
    background: yellow;
}
.form-control.ng-invalid.ng-touched {
   border: 1px solid red;
 }
 .loading{
	 position : fixed;
	 z-index: 100; 
	 -ms-opacity : 0.4;
	 opacity : 50;
	 background-repeat : no-repeat;
	 background-position : center;
	 left : 0;
	 bottom : 0;
	 right : 0;
	 top : 0;
	
 }
 
 /* Center the loader */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add animation to "page content" */
.animate-bottom {
 position:fixed;
 left:46%;
 top:50%;
 border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #ee1b2e;
  border-bottom: 16px solid #ee1b2e;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}


/* Begin css loading wait... */
#fountainTextG{
	position:fixed;
	margin:auto;
	top:50%;
	left:35%;
}

.fountainTextG{
	color:rgb(232,12,56);
	font-family:Arial;
	font-size:72px;
	text-decoration:none;
	font-weight:normal;
	font-style:normal;
	float:left;
	animation-name:bounce_fountainTextG;
		-o-animation-name:bounce_fountainTextG;
		-ms-animation-name:bounce_fountainTextG;
		-webkit-animation-name:bounce_fountainTextG;
		-moz-animation-name:bounce_fountainTextG;
	animation-duration:0.641s;
		-o-animation-duration:0.641s;
		-ms-animation-duration:0.641s;
		-webkit-animation-duration:0.641s;
		-moz-animation-duration:0.641s;
	animation-iteration-count:infinite;
		-o-animation-iteration-count:infinite;
		-ms-animation-iteration-count:infinite;
		-webkit-animation-iteration-count:infinite;
		-moz-animation-iteration-count:infinite;
	animation-direction:normal;
		-o-animation-direction:normal;
		-ms-animation-direction:normal;
		-webkit-animation-direction:normal;
		-moz-animation-direction:normal;
	transform:scale(.5);
		-o-transform:scale(.5);
		-ms-transform:scale(.5);
		-webkit-transform:scale(.5);
		-moz-transform:scale(.5);
}#fountainTextG_1{
	animation-delay:0.2225s;
		-o-animation-delay:0.2225s;
		-ms-animation-delay:0.2225s;
		-webkit-animation-delay:0.2225s;
		-moz-animation-delay:0.2225s;
}
#fountainTextG_2{
	animation-delay:0.269s;
		-o-animation-delay:0.269s;
		-ms-animation-delay:0.269s;
		-webkit-animation-delay:0.269s;
		-moz-animation-delay:0.269s;
}
#fountainTextG_3{
	animation-delay:0.3155s;
		-o-animation-delay:0.3155s;
		-ms-animation-delay:0.3155s;
		-webkit-animation-delay:0.3155s;
		-moz-animation-delay:0.3155s;
}
#fountainTextG_4{
	animation-delay:0.362s;
		-o-animation-delay:0.362s;
		-ms-animation-delay:0.362s;
		-webkit-animation-delay:0.362s;
		-moz-animation-delay:0.362s;
}
#fountainTextG_5{
	animation-delay:0.4085s;
		-o-animation-delay:0.4085s;
		-ms-animation-delay:0.4085s;
		-webkit-animation-delay:0.4085s;
		-moz-animation-delay:0.4085s;
}
#fountainTextG_6{
	animation-delay:0.455s;
		-o-animation-delay:0.455s;
		-ms-animation-delay:0.455s;
		-webkit-animation-delay:0.455s;
		-moz-animation-delay:0.455s;
}
#fountainTextG_7{
	animation-delay:0.5015s;
		-o-animation-delay:0.5015s;
		-ms-animation-delay:0.5015s;
		-webkit-animation-delay:0.5015s;
		-moz-animation-delay:0.5015s;
}
#fountainTextG_8{
	animation-delay:0.548s;
		-o-animation-delay:0.548s;
		-ms-animation-delay:0.548s;
		-webkit-animation-delay:0.548s;
		-moz-animation-delay:0.548s;
}
#fountainTextG_9{
	animation-delay:0.5945s;
		-o-animation-delay:0.5945s;
		-ms-animation-delay:0.5945s;
		-webkit-animation-delay:0.5945s;
		-moz-animation-delay:0.5945s;
}
#fountainTextG_10{
	animation-delay:0.641s;
		-o-animation-delay:0.641s;
		-ms-animation-delay:0.641s;
		-webkit-animation-delay:0.641s;
		-moz-animation-delay:0.641s;
}
#fountainTextG_11{
	animation-delay:0.6775s;
		-o-animation-delay:0.6775s;
		-ms-animation-delay:0.6775s;
		-webkit-animation-delay:0.6775s;
		-moz-animation-delay:0.6775s;
}
#fountainTextG_12{
	animation-delay:0.724s;
		-o-animation-delay:0.724s;
		-ms-animation-delay:0.724s;
		-webkit-animation-delay:0.724s;
		-moz-animation-delay:0.724s;
}
#fountainTextG_13{
	animation-delay:0.7705s;
		-o-animation-delay:0.7705s;
		-ms-animation-delay:0.7705s;
		-webkit-animation-delay:0.7705s;
		-moz-animation-delay:0.7705s;
}




@keyframes bounce_fountainTextG{
	0%{
		transform:scale(1);
		color:rgb(217,21,38);
	}

	100%{
		transform:scale(.5);
		color:rgb(255,255,255);
	}
}

@-o-keyframes bounce_fountainTextG{
	0%{
		-o-transform:scale(1);
		color:rgb(217,21,38);
	}

	100%{
		-o-transform:scale(.5);
		color:rgb(255,255,255);
	}
}

@-ms-keyframes bounce_fountainTextG{
	0%{
		-ms-transform:scale(1);
		color:rgb(217,21,38);
	}

	100%{
		-ms-transform:scale(.5);
		color:rgb(255,255,255);
	}
}

@-webkit-keyframes bounce_fountainTextG{
	0%{
		-webkit-transform:scale(1);
		color:rgb(217,21,38);
	}

	100%{
		-webkit-transform:scale(.5);
		color:rgb(255,255,255);
	}
}

@-moz-keyframes bounce_fountainTextG{
	0%{
		-moz-transform:scale(1);
		color:rgb(217,21,38);
	}

	100%{
		-moz-transform:scale(.5);
		color:rgb(255,255,255);
	}
}
/* end css loading wait... */

div.modalLv2 .modal-content {
    max-height: 600px;
    overflow: auto;
}
.colorCancel {
	color:red;
}
.form-control.ng-invalid-required {
    border: 1px solid red;
}
.ui-select-container.ng-invalid-required {
    border: 1px solid red;
}

.radio.ng-invalid-required {
    border: 1px solid red;
}
/* cms-css */

.pru-group-menu .panel-heading {
	border-left: 4px solid #f00;
	background: #f8f8f8;
}

.pru-group-menu .panel {
	border: 0px;
}
.pru-group-menu .panel-body a {
	font-size: 12px;
}
.pru-group-menu .panel-group {
	margin-bottom: 2px !important;
}
.pru-group-menu .panel-group .panel-heading + .panel-collapse > .list-group, .panel-group .panel-heading + .panel-collapse > .panel-body {
	background: #fff;
	border-left: 4px solid #efefef;
}
.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 13px;
    color: inherit;
}
#footer {
/*   z-index: 100000; */
  margin: 0;
  width: 100%;
  float: left;
  padding: 0;
  position:fixed;
  bottom:0;
}
#content{
	margin-bottom: 100px;
}
::-ms-clear {
    display: none;
}

.scrollShowBtn {
    display: block;
    position: absolute;
    bottom: 45px;
    right: 190px;
}
/* set z-index pagination default */
.btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:hover, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus, .btn-group > .btn:hover {
     z-index: 0 !important;
}
.pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
    z-index: 0 !important;
    color: #23527c;
    background-color: #eee;
    border-color: #ddd;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
     z-index: 0 !important;
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7;
}
.ui-select-toggle{display:flex;justify-content:space-between;}
.ui-select-match-text{overflow-x:hidden; text-overflow:ellipsis;}
.ui-select-placeholder{overflow-x:hidden; text-overflow:ellipsis;}
.margin-top-5px{margin-top: 5px;}
.highlightedBlueText {
    background: #66FFFF;
}
.img-w-question{width:14px;}
.color-blue{color:#0000FF;}

